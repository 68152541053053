@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Volkhov", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: .25px;
 
}
.hebrew{
  -moz-transform: scale(-1, 1);
-webkit-transform: scale(-1, 1);
-o-transform: scale(-1, 1);
-ms-transform: scale(-1, 1);
transform: scale(-1, 1);
}
.LanguageSelector{
  background:black;
}
/* .app{
  direction: rtl;
  text-align: right;
} */



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.mySwiperslider{
  padding-left: 0px!important;
  padding-right: 0px!important;
  width: 32px!important;
}

/* Door Animation */
#banner { 

  background-size: cover; 
  height: 500px; 
  border: solid 3px #006baf; 
  text-align: center;
  position: relative;  
  overflow: hidden 
}

.doorHoverLeft { 
  position: absolute; 
  left: 0%; 
  top: 0; 
  bottom: 0; 
  width: 50%; 
  transition: 3s ease-in-out; 
  box-shadow: inset 0 0 0 1px rgba(0,0,0,0.40); 
  background: rgba(226,226,226,0.35); 
  background: -moz-linear-gradient(-45deg, rgba(226,226,226,0.35) 0%, rgba(219,219,219,0.35) 50%, rgba(209,209,209,0.35) 51%, rgba(254,254,254,0.35) 100%); 
  background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(226,226,226,0.35)), color-stop(50%, rgba(219,219,219,0.35)), color-stop(51%, rgba(209,209,209,0.35)), color-stop(100%, rgba(254,254,254,0.35))); 

  background: -webkit-linear-gradient(-45deg, rgba(226,226,226,0.35) 0%, rgba(219,219,219,0.35) 50%, rgba(209,209,209,0.35) 51%, rgba(254,254,254,0.35) 100%); 

  background: -o-linear-gradient(-45deg, rgba(226,226,226,0.35) 0%, rgba(219,219,219,0.35) 50%, rgba(209,209,209,0.35) 51%, rgba(254,254,254,0.35) 100%); 
  background: -ms-linear-gradient(-45deg, rgba(226,226,226,0.35) 0%, rgba(219,219,219,0.35) 50%, rgba(209,209,209,0.35) 51%, rgba(254,254,254,0.35) 100%); 
  background: linear-gradient(135deg, rgba(226,226,226,0.35) 0%, rgba(219,219,219,0.35) 50%, rgba(209,209,209,0.35) 51%, rgba(254,254,254,0.35) 100%); filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2e2e2', endColorstr='#fefefe', GradientType=1 );
}
.doorHoverRight { 
  position: absolute; 
  right: 0%; 
  top: 0; 
  bottom: 0; 
  width: 50%; 
  transition: 3s ease-in-out; 
  box-shadow: inset 0 0 0 1px rgba(0,0,0,0.40); 
  background: rgba(226,226,226,0.35); 
  background: -moz-linear-gradient(-45deg, rgba(226,226,226,0.35) 0%, rgba(219,219,219,0.35) 50%, rgba(209,209,209,0.35) 51%, rgba(254,254,254,0.35) 100%); 
  background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(226,226,226,0.35)), color-stop(50%, rgba(219,219,219,0.35)), color-stop(51%, rgba(209,209,209,0.35)), color-stop(100%, rgba(254,254,254,0.35))); 

  background: -webkit-linear-gradient(-45deg, rgba(226,226,226,0.35) 0%, rgba(219,219,219,0.35) 50%, rgba(209,209,209,0.35) 51%, rgba(254,254,254,0.35) 100%); 

  background: -o-linear-gradient(-45deg, rgba(226,226,226,0.35) 0%, rgba(219,219,219,0.35) 50%, rgba(209,209,209,0.35) 51%, rgba(254,254,254,0.35) 100%); 
  background: -ms-linear-gradient(-45deg, rgba(226,226,226,0.35) 0%, rgba(219,219,219,0.35) 50%, rgba(209,209,209,0.35) 51%, rgba(254,254,254,0.35) 100%); 
  background: linear-gradient(135deg, rgba(226,226,226,0.35) 0%, rgba(219,219,219,0.35) 50%, rgba(209,209,209,0.35) 51%, rgba(254,254,254,0.35) 100%); filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2e2e2', endColorstr='#fefefe', GradientType=1 );
}
#banner:hover .doorHoverLeft { 
  left: -50%; 
}
#banner:hover .doorHoverRight { 
  right: -50%; 
}
.door-button{
  z-index: 199999999;
  position: absolute;
  right: 47%;
  top: 45%;
}
/* Door Animation */
.collection-filter{
  width: 30%;
}
.collection-prod{
  width: 70%;
}
.product-pic{
  width: 50%;
}
.product-info{
  width: 50%;
}
.pill-slide {
  background-color: #7f1d1d; /* Adjust as needed */
  border-radius: 20px; /* Adjust the border-radius to make it pill-shaped */
  padding: 10px; /* Adjust padding as needed */
  color: #ffffff; /* Adjust text color as needed */
  text-align: center;

}
.sc-gEvEer{
  max-height: 200px;
  overflow: auto;
  z-index: 1999999;
}
/* .sc-dcJsrY{
  position: relative;
} */
.dYrfwz{
z-index: 0;
}
.sreach-book > div{
  z-index: 19!important;
}
.form-in input{
  border: 1px solid rgb(80, 80, 80);
  padding: 10px;
}
.form-in select{
  border: 1px solid rgb(80, 80, 80);
  padding: 10px;
}
.form-in textarea{
  border: 1px solid rgb(80, 80, 80);
  padding: 10px;
}
.tilt-neon {
  font-family: "Tilt Neon", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}
.vrttext{
  writing-mode: vertical-rl;
}
.fade-in {
  animation: fadeInAnimation 0.5s ease-in;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.doorText { 
  /* background-color: rgba(253,217,33,0.50);  */
  color: #333; 
  font-family: Verdana, sans-serif; 
  font-size: 56px; 
  font-weight: bold; 
  line-height: 45px; 
  height: 45px; 
  /* border:solid 1px rgba(0,0,0,0.15);  */
  /* border-width:1px 0; */
  position: absolute; 
  top: 34%;  
  padding: 0 0 0 20px; 
  left: 1px; 
  right: 1px; 
  margin-top: -22.5px; 
  text-shadow: 0 1px 1px rgba(255,255,255,0.25); 
}
.TLeft{
left: 30%;
}
.TRight{
  right: 40%;
}
.react-tabs__tab--selected{
background-color: #7F481C!important;
color: #fff!important;

}
.react-tbs{
  padding: 5px 10px;
  padding-right: 5px;
  border: 1px solid rgb(53, 53, 53);
  border-radius: 5px 5px 0 0;
}
/* resize panel */
.splitter {
  border: 1px solid rgb(203 213 225);
  align-items: center;
  display: flex;

  height: 722px;
  width: 100%;
}
.splitter__first {
  width: 70%;
  height: 100%;
  min-width: 700px;
  align-items: center;
  display: block;
  justify-content: center;
}
.splitter__resizer {
  background: rgb(203 213 225);
  height: 100%;
  width: 8px;

  cursor: ew-resize;
  user-select: none;
  touch-action: none;
}
.splitter__second {
  flex: 1;

  align-items: center;
  display: flex;
  justify-content: center;
}

.custom-vertical-splitter {
  border: 1px solid rgb(203, 213, 225);
  display: flex;
  height: 722px;
  width: 100%;
  flex-direction: column;
}

.custom-vertical-splitter__top-pane,
.custom-vertical-splitter__bottom-pane {
  align-items: center;
  display: block;
  justify-content: center;
  min-height: 100px;
  width: 100%;
}



.custom-vertical-splitter__bottom-pane {
  flex: 1;
}

.custom-vertical-splitter__resizer {
  background: rgb(203, 213, 225);
  cursor: ns-resize;
  height: 8px;
  width: 100%;
  user-select: none;
  touch-action: none;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #F1F1F1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6D3815;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7f1d1d;
}

#pdfviewer {
  height: 640px;
}
#linear-pdfviewer{
  width: fit-content;
}
#uploader-pdfviewer{
  display: none;
}
.content-wrapper-pdfviewer{
  border: 1px dashed #c3c3cc;
  position: relative;
  text-align: center;
  padding: 20px 0 10px;
}

.e-pv-e-sign-form-field-property{
  width: 72px;
  height: 72px;
  margin: 9px;
  border: 1px solid lightgrey!important;
  border-radius: 8px;
  display: grid;
  place-content: center;
}

#e-pv-e-sign-save{
  border: 1px solid black;
  border-radius: 16px;
}

#e-pv-e-sign-finishbtn{
  border: 1px solid black;
  border-radius: 16px;
  font-size: 12px;
}
.e-pv-e-sign-user-dropdown .e-input-group:not(.e-float-icon-left):not(.e-float-input).e-input-focus::before,.e-pv-e-sign-user-dropdown .e-input-group:not(.e-float-icon-left):not(.e-float-input).e-input-focus::after{
  width:0px;
}

#e-pv-e-sign-user-field{    
  color: #222933;
  font-family: Heebo, open sans, "sans-serif", -apple-system, BlinkMacSystemFont;
  border-radius: 4px;
  display: flex;     
  position: relative;
  left: 1px;    
}
#e-pv-e-sign-toolbar-user-viewer.e-toolbar .e-toolbar-items .e-toolbar-left .e-toolbar-item:first-child{
  margin-left: 0px !important;
}

#e-pv-e-sign-toolbar-user-viewer{
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 1px 0px rgba(0, 0, 0, 0.30);
  z-index: 1111;
}
#e-pv-e-sign-defaultSidebar{    
  height:640px;
  position: absolute;    
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
}

.e-pv-e-sign-user-dropdown > :first-child {
  border: none !important;
}

#magnificationToolbar {
  background: transparent;
  height: auto;
  min-height: 56px;
  width: auto;
  border: none;
  position: absolute;
  z-index: 900;
  top: calc(100% - 140px);
  left: calc(100% - 150px);
  transform: rotate(90deg);
}

div#magnificationToolbar.e-toolbar .e-toolbar-items {
  background: transparent;
  padding: 2px 3px 2px 2px;
}

#magnificationToolbar.e-toolbar .e-tbar-btn {
  border-radius: 50%;
  min-height: 30px;
  min-width: 30px;
  border: 1px solid #c8c8c8;
}

#customToolbar {
  top: 20px;
  z-index: 900;
}

.e-bookmark-popup {
  height: 300px;
  max-width: 300px;
}

.e-text-search-popup {
  height: 104px;
  max-width: 348px;
}

.e-custom-search-input {
  width: 234px;
}

.e-text-search-popup .e-footer-content,
.e-bookmark-popup .e-footer-content {
  padding: 0;
  height: 0;
}

.search-button,
.search-button:disabled,
.search-button:focus,
.search-button:hover {
  background: transparent;
  box-shadow: none;
}

#popup .e-dlg-content {
  padding-left: 0;
  padding-bottom: 0;
}

.e-pv-bookmarks {
  min-width: 234px;
}

.e-pv-current-page-number {
  width: 46px;
  height: 28px;
  text-align: center;
}

.material .e-pv-current-page-number {
  border-width: 1px;
}

.e-btn-icon.e-pv-zoom-out-icon.e-icons {
  transform: rotate(90deg);
}

.flex-container {
  display: flex;
  justify-content: flex-end;
}

.render-mode-info {
  background: none;
  border: none;
  padding-left: 0px;
}

.render-mode-info .render-mode-info-icon {
  height: 16px;
  width: 16px;
}

.switchLabel {
  font-family: "Segoe UI", "GeezaPro", "DejaVu Serif", sans-serif;        
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.24px;
  text-align: right;
  font-size: 14px;
}

.render-mode-info .render-mode-info-icon::before {
  line-height: 0.5rem;
}

.buttonSwitch {
  Width: 40px;
  Height: 24px;
}

.pdfviewer-property-section {
  padding: 20px 15px;
}

.pdfviewer-contextmenu-checkbox-label {
  padding: 10px;
}

.pdfviewer-control-section {
  border-right: 1px solid #D7D7D7;
}

.pdfviewer-property-container {
  padding: 20px 15px;
}

.pdfviewer-document-list{

  #defaultDialog {
      left: 23%;
      top: 0% ! important;
  }
  
  #targetElement .dlgbtn {
      margin-right: 10px;
  }
  
  @media screen and (min-width: 150px) and (max-width: 480px) {
      .control-section {
          margin-bottom: 30px;
      }
  
      .control-wrapper {
          margin-bottom: 0px;
      }
  }
  
  #defaultDialog .form-title {
      width: 100%;
      text-align: center;
      padding: 10px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.70);
  }
  
  #defaultDialog .e-error,
  #defaultDialog .e-float-text {
      font-weight: 500;
  }
  
  #defaultDialog .e-custom-label {
      font-size: 14px;
      font-weight: 500;
      margin-left: 20px;
  }
  
  #defaultDialog #formId {
      padding-top: 10px;
      border: 1px solid #ccc;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.36);
      border-radius: 5px;
      background: #f9f9f9;
      height: 250px;
      overflow-y: scroll;
  }
  
  .material-dark #defaultDialog #formId,
  .material3-dark #defaultDialog #formId,
  .bootstrap5-dark #defaultDialog #formId {
      background: #212529;
  }
  
  .bootstrap-dark #defaultDialog #formId {
      background: #1a1a1a;
  }
  
  .fabric-dark #defaultDialog #formId {
      background: #201f1f;
  }
  
  .tailwind-dark #defaultDialog #formId {
      background: #1f2937;
  }
  
  .fluent-dark #defaultDialog #formId {
      background: #111111;
  }

  .e-pv-fit-page-icon::before {
      content: '\e91b';
      font-family: "e-icons";
  }
 
  
  .material-dark #defaultDialog .form-title,
  .material3-dark #defaultDialog .form-title,
  .bootstrap-dark #defaultDialog .form-title,
  .fabric-dark #defaultDialog .form-title,
  .tailwind-dark #defaultDialog .form-title,
  .bootstrap5-dark #defaultDialog .form-title,
  .fluent-dark #defaultDialog .form-title {
      color: rgb(249 249 249);
  }
  
  .highcontrast #defaultDialog form,
  .highcontrast #defaultDialog .header {
      color: #fff;
      background: #000000;
  }
  
  #defaultDialog .header {
      color: rgba(0, 0, 0, 0.7);
  }
  
  #defaultDialog .form-horizontal .form-group {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 5px;
  }
  
  #defaultDialog .e-submit-btn {
      height: 40px;
      width: 150px;
  }
  
  #defaultDialog .e-reset-btn {
      height: 40px;
      width: 150px;
  }
  
  #defaultDialog .submitRow {
      width: 320px;
      margin: 0px auto;
      height: 100px;
      padding-top: 25px;
  }
  
  #defaultDialog #DialogChart {
      text-align: center;
  }
  
  #defaultDialog {
      max-height: 500px;
  }
  
  .file-name-container {
      display: flex;
      justify-content: left;
  }
  
  .file-name-content {
      display: flex;
      align-items: center;
  }
  
  .icon-and-text {
      display: flex;
      align-items: left;
  }
  
  .file-name-text {
      text-align: center;
      margin-left: 8px;
      margin-top: 5px;
  }
 
}
.no-drop {cursor: no-drop;}
.penn{
  cursor: url('../public/images/pen.png'), auto;
}
.volume-item {
  cursor: pointer; /* Change cursor to pointer on hover */
  padding: 5px; /* Add some padding */
}

.separator {
  padding: 0 5px; /* Add spacing around the separator */
}
.swiper-button-prev::after, .swiper-button-next::after{
color:white;
border-radius: 15px;
background: #6D3815;
    width: 35px;
    height: 35px;
    padding: 1px 6px;
    font-size: 20px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    font-weight: bold;
}
@media print {
  html, body {
    display: block; /* Make sure the document is displayed */
    visibility: hidden; /* Initially hide everything */
  }

  /* Show only the printable area */
  #printable-area {
    visibility: visible; /* Make the printable area visible */
  }

  /* Hide elements that should not be printed */
  .no-print {
    display: none; /* Ensure these elements are not printed */
  }

  body {
    background: white; /* Ensure a white background for printing */
  }
}



/* Styles for the dialog */
.dialog-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.dialog-box {
  background: white;
  border-radius: 5px;
  max-width: 400px;
  width: 100%;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* For WebKit browsers */
.tab-scrl::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */  
}
#pdf-viewer-128_fileUploadElement{
  display: none;
}

.switch {
  width: 40px;
  height: 20px;
  border-radius: 20px;
  background-color: #ccc;
  position: relative;
  cursor: pointer;
}

.switch.active {
  background-color: #4caf50;
}

.switch.inactive {
  background-color: #f44336;
}

.switch::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: white;
  transition: all 0.3s;
}

.switch.active::before {
  left: 22px;
}



