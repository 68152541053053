/* src/components/CustomDropdown.css */
.custom-dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-button {
    padding: 8px 16px;
    /* background-color: #007bff; */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    z-index: 1;
  }
  
  .dropdown-item {
    padding: 10px 16px;
    cursor: pointer;
    font-size: 16px;
    color: gray; 
  }
  
  .dropdown-item:hover {
    background-color: #f2f2f2;
  }
  